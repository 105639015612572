<template>
  <div>
    <v-container grid-list-md fluid>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-alert :value="true" type="info">
            <label
              class="text-uppercase"
              @click="goCanais()"
              style="text-decoration: underline;cursor:pointer;"
            >CANAL: {{canal.razao_social}}</label>
          </v-alert>
          <v-tabs centered slider-color="primary" v-model="tabs">
            <v-tab>Lojas</v-tab>
            <v-tab-item>
              <LojaLista />
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
const LojaLista = () => import('./Loja-Lista')
const canalModule = () => import('./../../../services/unidade')
import { mapGetters } from 'vuex'

export default {
  name: 'LojaTabs',
  components: {
    LojaLista
  },
  mounted () {
    this.getCanal()
  },
  data () {
    return {
      tabs: 0,
      canal: {}
    }
  },
   computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    async getCanal () {
      let canalDecode = atob(this.$route.params.id)
      let canalService = await canalModule()
      let resp = await canalService.getByCodigo(this.$axios, canalDecode)
      if (resp.status === 200 && resp.data) {
        this.canal = resp.data
      }
    },
    async goCanais () {
      if (this.currentUser.tipo !== 'A') {
        return
      }
      this.$router.push('/canal')
    }
  }
}
</script>